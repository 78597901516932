import AppBarMenu from '../../dashboard/app-bar/AppBarMenu'
import Card from '../card/Card'
import DashboardLayout from '../../layouts/DashboardLayout'
import React from 'react'

interface Props {
    children: React.ReactNode
}

interface State {
    hasError: boolean
    errorMessage?: string
}

class ErrorBoundary extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = { hasError: false, errorMessage: '' }
    }

    static getDerivedStateFromError(error: Error): State {
        return { hasError: true, errorMessage: error.message }
    }

    render() {
        const { hasError } = this.state

        if (hasError) {
            return (
                <DashboardLayout
                    title='Client site error'
                    description='error description'
                    showPosts={false}
                    appBarContent={<AppBarMenu />}
                >
                    <div className='flex items-center justify-center h-screen w-full'>
                        <Card className='h-full w-full flex items-center justify-center flex-col bg-gray-100 shadow-lg rounded-lg'>
                            <h1 className='text-5xl'>Oops! Chyba.</h1>
                            <p className='text-lg pt-6'>Něco se pokazilo.</p>
                        </Card>
                    </div>
                </DashboardLayout>
            )
        }

        return this.props.children
    }
}

export default ErrorBoundary
