import { FiSearch } from 'react-icons/fi'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import React, { KeyboardEvent } from 'react'
import TextField from '../../base/text-field/TextField'

const SearchTextField = () => {
    const router = useRouter()
    const { t } = useTranslation()

    const onSearchKeyDown = ({ target, key }: KeyboardEvent<HTMLInputElement>) => {
        const inputElement = target as HTMLInputElement
        const value = inputElement.value
        if (key === 'Enter' && value?.length) {
            router.push({
                pathname: '/app/search',
                query: { searchTerm: value }
            })
        }
    }

    return (
        <TextField
            srOnlyLabel
            removePaddingTop
            inputClassName='!py-[0.75rem] !min-h-[2.563rem] !w-[16.563rem] !border-0 !bg-grey-100 '
            icon={<FiSearch className='!text-primary' />}
            label={t('frontend.dashboard.shared.appbar.search')}
            placeholder={t('frontend.dashboard.shared.appbar.search')}
            onKeyDown={onSearchKeyDown}
        />
    )
}

export default SearchTextField
